import React, { useState, useEffect, useRef } from 'react';
import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';

const ChessGamePage = () => {
    const [game] = useState(new Chess());
    const [fen, setFen] = useState(game.fen());
    const [showInvalid, setShowInvalid] = useState(false);
    const [difficulty, setDifficulty] = useState(15);
    const stockfish = useRef(null);

    useEffect(() => {
        stockfish.current = new Worker(`${process.env.PUBLIC_URL}/stockfish-16.1-lite-single.js`);

        stockfish.current.onmessage = (e) => {
            const bestMove = e.data.match(/bestmove\s(\w{4})/)?.[1];
            if (bestMove) {
                game.move({ from: bestMove.slice(0, 2), to: bestMove.slice(2, 4) });
                setFen(game.fen());
            }
        };
        return () => stockfish.current.terminate();
    }, [game]);

    const makeMove = (source, target) => {
        const move = game.move({ from: source, to: target });
        if (!move) {
            setShowInvalid(true);
            setTimeout(() => setShowInvalid(false), 1000);
            return false;
        }

        setFen(game.fen());

        stockfish.current.postMessage(`position fen ${game.fen()}`);
        stockfish.current.postMessage(`go depth ${difficulty}`);

        return true;
    };

    return (
        <div
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                padding: '15px',
                borderRadius: '10px',
                maxWidth: '600px',
                width: '90%',
                margin: '10px auto',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                border: '1px solid #ccc',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <h2 style={{ margin: '10px 0' }}>Play Chess vs Stockfish</h2>
            <Chessboard position={fen} onPieceDrop={makeMove} />

            <div style={{ width: '100%', margin: '15px 0', textAlign: 'center' }}>
                <label htmlFor="difficulty">Difficulty (Depth): {difficulty}</label>
                <input
                    type="range"
                    id="difficulty"
                    min="1"
                    max="20"
                    value={difficulty}
                    onChange={(e) => setDifficulty(parseInt(e.target.value, 10))}
                    style={{ width: '100%', marginTop: '5px' }}
                />
            </div>

            {showInvalid && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '80px',
                        color: 'red',
                        pointerEvents: 'none',
                    }}
                >
                    ❌
                </div>
            )}
        </div>
    );
};

export default ChessGamePage;

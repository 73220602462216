import React from 'react';

const ProjectsPage = () => {
    return (
        <div className="projects-page">
            <h1>My Projects</h1>
            <section>
                <h2>Project One</h2>
                <p>A brief description of Project One.</p>
            </section>
            <section>
                <h2>Project Two</h2>
                <p>A brief description of Project Two.</p>
            </section>
        </div>
    );
};

export default ProjectsPage;
import React from 'react';
import styled, { keyframes } from 'styled-components';

const ComputopiaPage = () => {
    return (
        <Container>
            <CamelliaAnimation />
            <ArticleSection>
                <ArticleTitle>Computopia</ArticleTitle>
                <ArticleSubtitle></ArticleSubtitle>
                <ArticleText>
                    <quote>
                        The civilization we will build as we approach the 21st century will not be a material civilization symbolized by huge structures,
                        but we will be an informational civilization [...] based on systems invisible to the eye,
                        power hidden in miniature elements and the power of human reason.

                        ~ Yoneji Masuda
                    </quote>
                    <p>
                        "Plan for the Information Society - a national goal  by the year 2000",
                        Japanese government implemented for many years.
                    </p>
                    <p>
                        The author of "Information Society as Post-Industrialized Society" made two assumptions:
                    </p>
                    <ol>
                        <li>
                            The information society will be a new type of society, quite different from today's society.
                            The force behind the creation and development of the society will be the production of information, and the foundation will be the telecommunications technology system.
                        </li>
                        <li>
                            The historical development patterns of industrial society can be used as a basis for building an analogous model of future society.
                        </li>
                    </ol>
                    <p>
                        The Computopia that Masuda envisioned for the 21st century would be based on the following ideas:
                    </p>
                    <ol>
                        <li>The search for and realization of the value of time.</li>
                        <li>Freedom of decision and equality of opportunity for the individual.</li>
                        <li>Developing diverse and voluntary communities.</li>
                        <li>Societies free from coercive forces.</li>
                    </ol>
                    <p>
                        In other words,
                        the new society will be based on self-actualized individuals organizing among themselves on the basis of common interests (information),
                        with the ability to directly influence their destiny (e.g. global elections),
                        who by acting together create added value for society as a whole.
                    </p>
                    <p>
                        The direction to the future is the resultant of historical and social forces.
                        In the direction of computopia will be futurists and visionaries, who would like to live better.
                    </p>
                </ArticleText>
            </ArticleSection>
        </Container>
    );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bloom = keyframes`
    0% {
        transform: scale(0.95) rotate(0deg);
        opacity: 0.9;
    }
    50% {
        transform: scale(1.05) rotate(5deg);
        opacity: 1;
    }
    100% {
        transform: scale(0.95) rotate(0deg);
        opacity: 0.9;
    }
`;

const CamelliaAnimation = () => (
    <StyledCamellia viewBox="0 0 150 150">
        <g>
            <path
                d="M75,0C33.6,0,0,33.6,0,75s33.6,75,75,75s75-33.6,75-75S116.4,0,75,0z M75,145c-38.6,0-70-31.4-70-70
                s31.4-70,70-70s70,31.4,70,70S113.6,145,75,145z"
                fill="#ff9800"
            />
        </g>
        <circle cx="75" cy="75" r="10" fill="#ff9800" />
    </StyledCamellia>
);

const StyledCamellia = styled.svg`
    width: 150px;
    height: 150px;
    animation: ${bloom} 4s ease-in-out infinite;
    margin: 20px auto;
`;

const ArticleSection = styled.section`
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
`;

const ArticleTitle = styled.h2`
    font-size: 28px;
    margin-bottom: 10px;
`;

const ArticleSubtitle = styled.h3`
    font-size: 22px;
    margin-bottom: 20px;
    color: #555;
`;

const ArticleText = styled.div`
    line-height: 1.6;
    font-size: 16px;
    color: #333;

    p {
        margin-bottom: 20px;
    }

    ol {
        margin-bottom: 20px;
        padding-left: 20px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    animation: ${fadeIn} 1.2s ease-out forwards;
`;

export default ComputopiaPage;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import profilePic from '../images/profile.jpg';
import ParticleBackground from '../components/ParticleBackground';

const HomePage = () => {
    return (
        <HomeContainer>
            <AnimatedContainer>
                <ParticleBackground />
                <ProfilePic src={profilePic} alt="Profile" />
                <Name>Kacper Bąk</Name>
                <JobTitle>Creator of Insivible Systems</JobTitle>
            </AnimatedContainer>
        </HomeContainer>
    );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HomeContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff; /* Plain background */
`;

const AnimatedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: ${fadeIn} 1.2s ease-out forwards;
`;

const ProfilePic = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 20px;
`;

const Name = styled.h1`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const JobTitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    text-align: center;
`;

export default HomePage;

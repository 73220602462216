import React, { useEffect, useState } from 'react';
import matter from 'gray-matter';

// postsList should be dynamically generated from the blog directory
function getPostsList() {
    const context = require.context('../../public/blog', false, /\.md$/);
    return context.keys().map((key) => key.replace('./', '').replace('.md', ''));
}

const postsList = [
    ...getPostsList(),
    // Add any additional slugs here if needed
];

const BlogList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      const loadedPosts = await Promise.all(
        postsList.map(async (slug) => {
          const res = await fetch(`${process.env.PUBLIC_URL}/blog/${slug}.md`);
          const text = await res.text();
          const { data } = matter(text);
          return { slug, ...data };
        }),
      );
      setPosts(loadedPosts.sort((a, b) => new Date(b.date) - new Date(a.date)));
    };
    loadPosts();
  }, []);

  return (
    <div>
      <h2>Blog Posts</h2>
      <ul>
        {posts.map((post) => (
          <li key={post.slug}>
            <a href={`#/blog/${post.slug}`}>{post.title}</a> — {post.date}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogList;

import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import SkillsPage from './pages/SkillsPage';
import NotFoundPage from './pages/NotFoundPage';
import ComputopiaPage from './pages/ComputopiaPage';
import ProjectsPage from './pages/ProjectsPage';
import MobileNav from './components/MobileNav';
import Footer from './components/Footer';
import Header from './components/Header';
import ParticleBackground from './components/ParticleBackground';
import ChessGamePage from './pages/ChessGamePage';
import './App.css';
import BlogPage from './pages/BlogPage';
import BlogPost from './components/BlogPost';

const BlogPostWrapper = () => {
    const { slug } = useParams();
    return <BlogPost slug={slug} />;
};

function App() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        const resizeObserveErrHandler = (e) => {
            if (e.message.includes('ResizeObserver loop completed')) {
                e.stopImmediatePropagation();
            }
        };
        window.addEventListener('error', resizeObserveErrHandler);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('error', resizeObserveErrHandler);
        };
    }, []);

    return (
        <div className="app" style={{ position: 'relative' }}>
            <ParticleBackground />
            {isMobile ? <MobileNav /> : <Header />}
            
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/skills" element={<SkillsPage />} />
                <Route path="/computopia" element={<ComputopiaPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/chess" element={<ChessGamePage />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:slug" element={<BlogPostWrapper />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import matter from 'gray-matter';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BlogPost = ({ slug }) => {
  const [content, setContent] = useState('');
  const [metadata, setMetadata] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/blog/${slug}.md`)
      .then((res) => res.text())
      .then((text) => {
        const { data, content } = matter(text);
        setMetadata(data);
        setContent(content);
      });
  }, [slug]);

  return (
    <article>
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/blog')}
        style={{ marginBottom: '20px' }}
        // centered
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        Back to Blog
      </Button>
      <h1>{metadata.title}</h1>
      <p>{metadata.date} — by {metadata.author}</p>
      <ReactMarkdown>{content}</ReactMarkdown>
    </article>
  );
};

export default BlogPost;
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { HashRouter } from 'react-router-dom';
import { Buffer } from 'buffer';

import './index.css';

window.Buffer = Buffer;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <HashRouter>
            <App />
        </HashRouter>
    </React.StrictMode>
);

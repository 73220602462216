import React from 'react';
import BlogList from '../components/BlogList';

const BlogPage = () => (
    <div>
        <BlogList />
    </div>
);

export default BlogPage;

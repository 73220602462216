import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './MobileNav.css';

const MobileNav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div>
            <FontAwesomeIcon
                icon={faBars}
                className="hamburger"
                onClick={handleToggleMenu}
                role="button"
                tabIndex={0}
            />
            {isMenuOpen && (
                <div className="mobile-nav">
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="close-button"
                        onClick={handleCloseMenu}
                        role="button"
                        tabIndex={0}
                    />
                    <Link to="/" className="nav-link" onClick={handleCloseMenu}>
                        Home
                    </Link>
                    <Link to="/computopia" className="nav-link" onClick={handleCloseMenu}>
                        Computopia
                    </Link>
                    <Link to="/chess" className="nav-link" onClick={handleCloseMenu}>
                        Chess
                    </Link>
                    <Link to="/blog" className="nav-link" onClick={handleCloseMenu}>
                        Blog
                    </Link>
                </div>
            )}
        </div>
    );
};

export default MobileNav;
